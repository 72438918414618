.root {
  margin-bottom: 16px;
  padding: 24px 24px 0 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #222222;
}

.closeIcon {
  cursor: pointer;
  transition-duration: .6s;
}

.closeIcon path {
  transition-duration: .2s;
}

.closeIcon:hover path{
  fill: #210364;
  transition-duration: .2s;
}

