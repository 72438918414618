.root {
  padding: 12px 32px 12px 32px;
  font-family: 'Poppins', sans-serif;
  height: 52px;
  font-style: normal;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  color: #888888;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  border-radius: 30px;
}

.root:active {
  border: 1px solid rgba(87, 162, 255, 0.8);
}

.root:focus {
  outline: none;
  border: 1px solid rgba(87, 162, 255, 0.8);
}