.root {
  display: flex;
  max-width: 100%;
  padding: 0 27px 0 28px;
  background: #FFFFFF;
  column-gap: 10px;
  align-items: center;
  max-height: 62px;
}

.textAreaWrapper {
  width: 100%;
  height: 48px;
}

.textAreaWrapper textarea {
  border: 0;
  resize: none;
  padding: 0;
  width: 100%;
  height: 48px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #292D32;
  overflow-y: hidden;
}

.textAreaWrapper textarea:focus {
  border: 0;
  box-shadow: none;
}

.buttonSend {
  background: rgba(37, 134, 255, 1);
  border-radius: 50px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emoji {
  cursor: pointer;
}
