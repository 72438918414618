.closeIcon {
  border-radius: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.divider {
  margin: 0 23px 10px 24px;
  max-width: 100%;
  background: #C4C4C4;
  height: 1px;
}

.chat {
  width: 410px;
  height: 446px;
}

@media screen and (max-width: 1064px) {
  .chat {
    width: 410px;
    height: 323px;
  }
}

.channel {
  width: 100%;
}

.messageList_desktop {
  height: 446px;
  overflow-y: auto;
}

.messageList_mobile {
  height: 210px;
  overflow-y: auto;
}

.messageList::-webkit-scrollbar {
  width: 0;
}

.message {
  margin-bottom: 24px;
}
