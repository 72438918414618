.root {
  display: flex;
  justify-content: space-between;
  position: absolute;
  align-items: flex-end;
}

.column {
  flex-direction: column;
  row-gap: 16px;
}

.row {
  flex-direction: row;
  column-gap: 16px;
}
