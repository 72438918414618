.root_mobile {
  position: absolute;
  right: 124px;
  bottom: 55px;

}

.root_desktop {
  position: absolute;
  bottom: 90px;
  right: 240px;
}

.handBlock {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translate(0, -50%);
}
